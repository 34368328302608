<template>
  <div class="issue-status">
    <v-toolbar flat>
      <v-btn icon @click="back">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>Aplicación</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form"  @submit="submit" class="new__issue-status__form ma-5">
        <v-row>
          <div class="title mb-3">Configuración</div>
        </v-row>
        <v-row class="mb-0">
          <v-col>
            <v-text-field
              outlined
              label="Versión mínima Android"
              v-model="androidMinVersion"
              :error-messages="androidMinVersionError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col>
            <v-text-field
              outlined
              label="Versión mínima iOS"
              v-model="iosMinVersion"
              :error-messages="iosMinVersionError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col>
            <v-text-field
              outlined
              label="URL Chatbot"
              v-model="chatbotUrl"
              :error-messages="chatbotUrlError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              outlined
              block
              color="primary"
              class="mt-5"
              :to="{name: 'workflows'}"
            >Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar v-model="snackbar">
      La configuración se ha actualizado
      <v-btn color="success" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { to } from '@/helpers'
// import rules from '@/constants/input-validation'
import {
  APPLICATION_GET_SETTINGS,
  APPLICATION_UPDATE_SETTINGS
} from '@/store/actions.type'
import { errorMessage } from '@/mixins'
import { mapGetters } from 'vuex'
export default {
  name: 'Application',
  props: {},
  created() {
    this.getSettings()
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
      androidMinVersion: '',
      androidMinVersionError: '',
      iosMinVersion: '',
      iosMinVersionError: '',
      chatbotUrl: '',
      chatbotUrlError: '',
      error: null,
      snackbar: false
    }
  },
  computed: {
    ...mapGetters(['getApplicationSettings'])
  },
  components: {},
  mixins: [errorMessage],
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    async getSettings() {
      this.isLoading = true
      const [err] = await to(this.$store.dispatch(APPLICATION_GET_SETTINGS))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.androidMinVersion = this.getApplicationSettings.androidMinVersion
        this.iosMinVersion = this.getApplicationSettings.iosMinVersion
        this.chatbotUrl = this.getApplicationSettings.chatbotUrl
        this.isLoading = false
      }
    },
    async submit(evt) {
      evt.preventDefault()
      const settings = {
        androidMinVersion: this.androidMinVersion,
        iosMinVersion: this.iosMinVersion,
        chatbotUrl: this.chatbotUrl
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(this.$store.dispatch(APPLICATION_UPDATE_SETTINGS, settings))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.snackbar = true
        this.isLoading = false
      }
    }
  }
}
</script>
